import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.62.3_async-validator@4._4y5z6g24ffhybde6lqg5cfvq5i/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.62.3_async-validator@4._4y5z6g24ffhybde6lqg5cfvq5i/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-only": () => import("/vercel/path0/middleware/admin-only.ts"),
  dashboard: () => import("/vercel/path0/middleware/dashboard.ts"),
  "guest-only": () => import("/vercel/path0/middleware/guest-only.ts"),
  "judge-only": () => import("/vercel/path0/middleware/judge-only.ts"),
  "not-user-only": () => import("/vercel/path0/middleware/not-user-only.ts"),
  "sponsor-only": () => import("/vercel/path0/middleware/sponsor-only.ts"),
  "user-only": () => import("/vercel/path0/middleware/user-only.ts"),
  "welcome-back": () => import("/vercel/path0/middleware/welcome-back.ts")
}