import { acceptHMRUpdate, defineStore } from 'pinia'

type AlertType = 'success' | 'error' | 'warning' | 'info'

interface Alert {
  id: string
  message: string
  type: AlertType
  delay: number
}

export const useAlertStore = defineStore('alert', () => {
  interface AlertTimeouts {
    [key: string]: NodeJS.Timeout
  }

  const alerts = ref<Alert[]>([])
  const alertTimeouts = ref<AlertTimeouts>({})

  function setAlert(message: string, state: AlertType, delay = 3000) {
    const alertId = Math.random().toString(36)
    const newAlert = {
      id: alertId,
      message,
      type: state,
      delay,
    }

    alerts.value.unshift(newAlert)

    alertTimeouts.value[alertId] = setTimeout(() => {
      removeAlert(alertId)
    }, delay)
  }

  function removeAlert(alertId: string) {
    if (alertTimeouts.value[alertId]) {
      clearTimeout(alertTimeouts.value[alertId])
      delete alertTimeouts.value[alertId]
    }

    alerts.value = alerts.value.filter(alert => alert.id !== alertId)
  }

  return {
    setAlert,
    removeAlert,
    alerts,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAlertStore, import.meta.hot))
