import { acceptHMRUpdate, defineStore } from 'pinia'
import type { User } from '~/types/prisma'
import { Roles } from '~/types/prisma'

export const useAuthStore = defineStore('auth', () => {
  const user = ref<User | null>(null)

  const isUser = computed(() => user.value?.role?.toUpperCase() === Roles.USER)

  const isReporter = computed(() => user.value?.role?.toUpperCase() === Roles.REPORTER)

  const isAdmin = computed(() => user.value?.role?.toUpperCase() === Roles.ADMIN || isReporter.value)

  const isJudge = computed(() => user.value?.role?.toUpperCase() === Roles.JUDGE)

  const isLeader = computed(() => user.value?.role?.toUpperCase() === Roles.LEADER)

  const isSponsor = computed(() => user.value?.role?.toUpperCase() === Roles.SPONSOR)

  const defaultUserPage = computed(() => {
    const role = (user.value?.role ?? 'default').toUpperCase()
    const pathsByRole: Record<string, string> = {
      ADMIN: '/admin',
      REPORTER: '/admin',
      JUDGE: '/judges',
      LEADER: '/dashboard',
      SPONSOR: '/dashboard',
      USER: '/dashboard',
      DEFAULT: '/',
    }

    return pathsByRole[role]
  })

  function setUser(newUser: User | null) {
    user.value = newUser
    console.log(user.value)
  }

  return {
    user,
    isUser,
    isAdmin,
    isJudge,
    isLeader,
    isSponsor,
    isReporter,
    defaultUserPage,
    setUser,
  }
},
{
  persist: {
    storage: persistedState.localStorage,
  },
},
)

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
