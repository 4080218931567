<script setup lang="ts">
import '@formkit/themes/genesis'
import { appName } from '~/constants'
import { useAuthStore } from '~/stores/auth'
import { useSentry } from '~/composables/useSentry'

const authStore = useAuthStore()
const { identifyUser } = useSentry()

const user = computed(() => authStore.user)

watch(user, (newUser) => {
  identifyUser(newUser)
  console.log('Identified user for Sentry', newUser)
}, {
  immediate: true,
  deep: true,
})

useHead({
  titleTemplate: titleChunk => titleChunk ? `${titleChunk} • ${appName}` : appName,
})
</script>

<template>
  <VitePwaManifest />
  <NuxtLayout>
    <NuxtPage />
    <Alerts />
  </NuxtLayout>
</template>
