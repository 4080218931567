import { generateClasses } from '@formkit/themes'
import { createProPlugin, inputs } from '@formkit/pro'
const pro = createProPlugin('fk-8b888c079f1', inputs)

function defaultToEmptyString(node) {
  if (node.value === undefined)
    node.input('', false)
}

const textInputClasses = {
  input: '!text-t-text !placeholder-t-text-light !text-sm !px-3 !font-sans',
  inner: '!shadow-none !overflow-hidden !border-2 !border-t-border !border !rounded-xl !bg-t-bg-hover !text-t-text',
  label: '!pb-2 !leading-none !text-lg !font-medium !font-accent !uppercase !text-t-text !tracking-wider',
  message: '!mt-1 !theme-error !text-t-text !bg-t-bg !rounded-xl !py-2 !px-3 !text-xs !font-medium',
  help: '!text-t-text-light !mt-1.5',
  wrapper: '!max-w-none !font-sans',
}

export default {
  plugins: [pro, defaultToEmptyString],
  config: {
    classes: generateClasses({
      form: {
        messages: '!theme-error !text-t-text !bg-t-bg !rounded-xl !py-2 !px-3 !text-xs !font-medium !mt-4',
        message: '!text-t-text !text-xs !font-medium !m-0 !p-0',
      },
      text: textInputClasses,
      search: {
        ...textInputClasses,
        input: `${textInputClasses.input} !appearance-none !hide-clear`,
      },
      email: textInputClasses,
      password: textInputClasses,
      textarea: {
        input: '!text-t-text !placeholder-t-text-light !text-sm !px-3 !font-sans',
        inner: textInputClasses.inner,
        label: textInputClasses.label,
        message: textInputClasses.message,
        help: textInputClasses.help,
        wrapper: textInputClasses.wrapper,
      },
      select: {
        ...textInputClasses,
        input: '!text-t-text !placeholder-t-text-light !text-sm !pl-3 !pr-9 !font-sans',
      },
      autocomplete: {
        ...textInputClasses,
        inner: `${textInputClasses.inner} !overflow-visible`,
        dropdownWrapper: 'rounded-xl my-2 shadow !max-h-[min(300px,30vh)]',
        listbox: 'bg-t-bg theme-neutral-light text-t-text py-1.5',
        option: 'transition-colors text-sm text-t-text px-4 py-2 bg-t-bg hover:bg-t-bg-hover cursor-pointer formkit-selected:bg-t-bg formkit-selected:theme-coral formkit-checked:bg-t-bg formkit-checked:theme-coral',
        selectedIcon: '!hidden',
      },
      number: textInputClasses,
      mask: textInputClasses,
      file: {
        // input: '!text-white border !border-primary',
        inner: textInputClasses.inner,
        label: textInputClasses.label,
        message: textInputClasses.message,
        help: textInputClasses.help,
        noFiles: '!text-sm !text-t-text-light',
        fileList: '!text-sm !text-t-text-light !p-0',
        fileRemove: '!text-sm !flex !items-center !gap-1.5',
        fileItem: 'h-10 px-3',
        fileName: '!truncate !mr-2',
      },
      checkbox: {
        inner: '',
        decorator: 'w-5 h-5 !shadow-none !overflow-hidden !border-2 !bg-none !border-t-border !border-solid !border !rounded-lg !bg-t-bg-hover !text-t-text',
        decoratorIcon: '!text-t-text',
        label: '!leading-none !text-lg !font-medium !font-accent !uppercase !text-t-text !tracking-wider',
        message: textInputClasses.message,
        help: textInputClasses.help,
        wrapper: textInputClasses.wrapper,
      },
      // text: {
      //   outer: 'mb-5',
      //   label: 'block mb-1 font-bold text-sm',
      //   inner: 'text-white max-w-md border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500',
      //   input: 'text-white w-full h-10 px-3 border-none text-base placeholder-gray-400',
      //   help: 'text-xs text-gray-500',
      //   messages: 'list-none p-0 mt-1 mb-0',
      //   message: 'text-red-500 mb-1 text-xs',
      // },
      // email: {
      //   outer: 'mb-5',
      //   label: 'block mb-1 font-bold text-sm',
      //   inner: 'text-white max-w-md border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500',
      //   input: 'text-white w-full h-10 px-3 border-none text-base placeholder-gray-400',
      //   help: 'text-xs text-gray-500',
      //   messages: 'list-none p-0 mt-1 mb-0',
      //   message: 'text-red-500 mb-1 text-xs',
      // },
      // textarea: {
      //   outer: 'mb-5',
      //   label: 'block mb-1 font-bold text-sm',
      //   inner: 'text-white max-w-md border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500',
      //   input: 'text-white w-full h-10 px-3 border-none text-base placeholder-gray-400',
      //   help: 'text-xs text-gray-500',
      //   messages: 'list-none p-0 mt-1 mb-0',
      //   message: 'text-red-500 mb-1 text-xs',
      // },
      // submit: {
      //   inner: 'bg-red-200',
      // },
    }),
  },
}
