const style = {
  card: 'p-4 lg:p-5 rounded-2xl bg-t-bg text-t-text-light',
  cardHeader: '',
  cardTitle: 'text-2xl heading text-t-text',
  cardDescription: 'text-sm mt-2 text-t-text-light leading-relaxed grid gap-2.5',
  checkoutListItem: {
    wrapper: 'flex gap-3 not-last:pb-2.5 not-first:pt-2.5 not-last:border-b border-t-border',
    title: 'flex-1 text-sm mt-1',
    titleFinal: 'flex-1 mt-1 font-accent text-xl font-bold uppercase tracking-wider text-t-text',
    quantity: 'shrink-0 font-accent text-lg font-bold tracking-wider text-t-text',
    quantityFinal: 'shrink-0 font-accent text-2xl font-bold text-t-text flex items-center',
    button: 'shrink-0 w-5 h-5 transition-opacity hover:opacity-50',
  },
}

export default style
