/**
 * Model User
 *
 */
export interface User {
  id: string
  createdAt: Date
  updatedAt: Date
  firstName: string | null
  lastName: string | null
  role: Role
  email: string
  phone: string | null
  stripeId: string | null
  hash: string
  yearsInvited: UserYear[] | null
  allowMultiple: boolean
  resetPasswordToken: string | null
  resetPasswordExpires: Date | null
}

/**
 * Model UserYear
 *
 */
export interface UserYear {
  id: string
  createdAt: Date
  updatedAt: Date
  inviteCode: string
  accepted: boolean
  userAcceptedAt: Date
  year: number
  User: User
  userId: string
  UserInvite: UserInvite
  userInviteId: string
}

/**
 * Model Announcement
 *
 */
export interface Announcement {
  id: string
  createdAt: Date
  updatedAt: Date
  published: boolean
  title: string
  content: string
  smsContent: string | null
  email: boolean
  sms: boolean
}

/**
 * Model UserInvite
 *
 */
export interface UserInvite {
  id: string
  createdAt: Date
  updatedAt: Date
  accepted: boolean
  name: string | null
  role: Role | null
  email: string
  token: string
}

/**
 * Model TeamLeaderInvite
 *
 */
export interface TeamLeaderInvite {
  id: string
  createdAt: Date
  updatedAt: Date
  email: string
  token: string
}

/**
 * Model CompetitorInvite
 *
 */
export interface CompetitorInvite {
  id: string
  createdAt: Date
  updatedAt: Date
  email: string
  token: string
}

/**
 * Model CaptainProfile
 *
 */
export interface CaptainProfile {
  id: string
  createdAt: Date
  updatedAt: Date
  userId: string
}

/**
 * Model CompetitorProfile
 *
 */
export interface CompetitorProfile {
  id: string
  firstName: string
  lastName: string
  createdAt: Date
  updatedAt: Date
  userId: string
}

/**
 * Model TradeProfile
 *
 */
export interface TradeProfile {
  id: string
  createdAt: Date
  updatedAt: Date
  companyName: string
  description: string
  quantity: number
  totalDonation: number
  logo: string
  sponsorLevel: string | null
  isFinal: boolean
  userId: string
}

/**
 * Model Cart
 *
 */
export interface Cart {
  id: string
  createdAt: Date
  updatedAt: Date
  invoiceId: string | null
  paidAt: Date | null
  userId: string
}

/**
 * Model RafflePurchase
 *
 */
export interface RafflePurchase {
  id: string
  createdAt: Date
  updatedAt: Date
  invoiceId: string | null
  ticketNumber: number
  firstName: string | null
  lastName: string | null
  total: number | null
  email: string | null
  isCash: boolean | null
  paidAt: Date | null
  userId: string | null
  raffleId: string
  raffleWinnerId: string | null
}

/**
 * Model Raffle
 *
 */
export interface Raffle {
  id: string
  name: string
  img: string
  priority?: number
  description: string | any
  createdAt: Date
  updatedAt: Date
  stripeId: string
  winnerId: string | null
}

/**
 * Model Donation
 *
 */
export interface Donation {
  id: string
  firstName: string
  lastName: string
  email: string
  companyName: string | null
  amount: number
  paidAt: Date | null
  createdAt: Date
  updatedAt: Date
  stripePaymentId: string
}

/**
 * Model Roster
 *
 */
export interface Roster {
  id: string
  createdAt: Date
  updatedAt: Date
  memberId: string
  signedInById: string
}

/**
 * Model InvoiceItems
 *
 */
export interface InvoiceItems {
  id: string
  createdAt: Date
  updatedAt: Date
  deletedAt: Date | null
  stripeId: string
  lineItemId: string | null
  description: string | null
  amount: number | null
  cartId: string
}

/**
 * Model Purchase
 *
 */
export interface Purchase {
  id: string
  createdAt: Date
  updatedAt: Date
  isCheck: boolean
  recievedAt: Date | null
  cartId: string
}

/**
 * Model Member
 *
 */
export interface Member {
  id: string
  createdAt: Date
  updatedAt: Date
  deletedAt: Date | null
  isLeader: boolean
  email: string | null
  phone: string | null
  firstName: string | null
  lastName: string | null
  teamId: string
  userId: string | null
}

/**
 * Model Team
 *
 */
export interface Team {
  id: string
  createdAt: Date
  updatedAt: Date
  deletedAt: Date | null
  number: number | null
  name: string | null
  song: string | null
  isFly: boolean
  division: TeamDivision | null
  tbd: boolean
  contributionId: string | null
  invoiceItemId: string | null
  guideFirstName?: string
  guideLastName?: string
  guideEmail?: string
  guidePhone?: string
  isGuided?: boolean
}

/**
 * Model Contribution
 *
 */
export interface Contribution {
  id: string
  createdAt: Date
  updatedAt: Date
  purchaseId: string | null
  tradeProfileId: string | null
}

/**
 * Model Product
 *
 */
export interface Product {
  id: string
  createdAt: Date
  updatedAt: Date
}

/**
 * Model Catch
 *
 */
export interface Catch {
  id: string
  createdAt: Date
  updatedAt: Date
  length: number
  day: number
  judgeUserId: string
  teamId: string
  competitorProfileId: string | null
}

/**
 * Model SpotsCatch
 *
 */
export interface SpotsCatch {
  id: string
  createdAt: Date
  updatedAt: Date
  numberOfSpots: number | null
  day: number
  judgeUserId: string
  memberId: string
}

/**
 * Model Waitlist
 *
 */
export interface Waitlist {
  id: string
  createdAt: Date
  updatedAt: Date
  email: string
  firstName: string
  lastName: string
  phone: string
  message: string
  previous: boolean | null
}

/**
 * Model GuestList
 *
 */
export interface GuestList {
  id: string
  createdAt: Date
  updatedAt: Date
  firstName: string
  lastName: string
  isPaid: boolean
  isCheckedIn: boolean
  addedById: string
}

/**
 * Model AdminSettings
 *
 */
export interface AdminSettings {
  id: number
  description: string
  status: boolean | null
  value: number | null
  date: Date | null
}

/**
 * Enums
 */

// Based on
// https://github.com/microsoft/TypeScript/issues/3192#issuecomment-261720275

export enum Roles {
  ADMIN = 'ADMIN',
  SPONSOR = 'SPONSOR',
  JUDGE = 'JUDGE',
  LEADER = 'LEADER',
  USER = 'USER',
  REPORTER = 'REPORTER',
}

export type Role = (typeof Roles)[keyof typeof Roles]

export enum TeamDivisions {
  FLY = 'FLY',
  BARTS = 'BARTS',
}

export type TeamDivision = (typeof TeamDivisions)[keyof typeof TeamDivisions]
