import { default as _91_46_46_46all_930i5ctWJQq5Meta } from "/vercel/path0/pages/[...all].vue?macro=true";
import { default as indexutcnovDtlEMeta } from "/vercel/path0/pages/about/index.vue?macro=true";
import { default as anglersjVbS35eJgnMeta } from "/vercel/path0/pages/admin/anglers.vue?macro=true";
import { default as announcementsLm1aQPhz1DMeta } from "/vercel/path0/pages/admin/announcements.vue?macro=true";
import { default as auction9d8jL4iduQMeta } from "/vercel/path0/pages/admin/auction.vue?macro=true";
import { default as dashboardYi2jyZZMHDMeta } from "/vercel/path0/pages/admin/dashboard.vue?macro=true";
import { default as djHfTXVS8oQPMeta } from "/vercel/path0/pages/admin/dj.vue?macro=true";
import { default as guestscBZEpVIcdFMeta } from "/vercel/path0/pages/admin/guests.vue?macro=true";
import { default as indexG7R2Mya2P4Meta } from "/vercel/path0/pages/admin/index.vue?macro=true";
import { default as inviteswPLKclmWYXMeta } from "/vercel/path0/pages/admin/invites.vue?macro=true";
import { default as judgesovpAjaflPyMeta } from "/vercel/path0/pages/admin/judges.vue?macro=true";
import { default as leaderboardHpMdCXa3T6Meta } from "/vercel/path0/pages/admin/leaderboard.vue?macro=true";
import { default as paymentsqWvZO2qmb3Meta } from "/vercel/path0/pages/admin/payments.vue?macro=true";
import { default as photosrBxQ8j16AQMeta } from "/vercel/path0/pages/admin/photos.vue?macro=true";
import { default as raffles_45tableaXPHSNq8tDMeta } from "/vercel/path0/pages/admin/raffles-table.vue?macro=true";
import { default as raffles0oTdJAQC0cMeta } from "/vercel/path0/pages/admin/raffles.vue?macro=true";
import { default as random_45winners5Ce5KpxK7hMeta } from "/vercel/path0/pages/admin/random-winners.vue?macro=true";
import { default as scoresTR4Ls4Ws12Meta } from "/vercel/path0/pages/admin/scores.vue?macro=true";
import { default as settingsWTf6HiURFlMeta } from "/vercel/path0/pages/admin/settings.vue?macro=true";
import { default as teams97sw7hFG3eMeta } from "/vercel/path0/pages/admin/teams.vue?macro=true";
import { default as waitlistOZLa6IiSYOMeta } from "/vercel/path0/pages/admin/waitlist.vue?macro=true";
import { default as indexuGmhZybC4TMeta } from "/vercel/path0/pages/announcements/index.vue?macro=true";
import { default as auctionj0CBmUpJnUMeta } from "/vercel/path0/pages/auction.vue?macro=true";
import { default as cart3AXNnOUus9Meta } from "/vercel/path0/pages/cart.vue?macro=true";
import { default as index295g7uS6eNMeta } from "/vercel/path0/pages/causes/index.vue?macro=true";
import { default as checkout_confirmGrz9u0XPeUMeta } from "/vercel/path0/pages/checkout_confirm.vue?macro=true";
import { default as indexraiq4bUafSMeta } from "/vercel/path0/pages/checkout/index.vue?macro=true";
import { default as paymentuM71ATR39tMeta } from "/vercel/path0/pages/checkout/payment.vue?macro=true";
import { default as indexRIIpWdUktDMeta } from "/vercel/path0/pages/dashboard/index.vue?macro=true";
import { default as indexZ7PfRbLiPHMeta } from "/vercel/path0/pages/donate/index.vue?macro=true";
import { default as embedBgEWVpGAHyMeta } from "/vercel/path0/pages/embed.vue?macro=true";
import { default as indexBDH30Z85iOMeta } from "/vercel/path0/pages/forgot/index.vue?macro=true";
import { default as sentcZrERVUUBtMeta } from "/vercel/path0/pages/forgot/sent.vue?macro=true";
import { default as indexoqVizrzsXuMeta } from "/vercel/path0/pages/honoree/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91token_93VwOOSYwjSdMeta } from "/vercel/path0/pages/invite/[token].vue?macro=true";
import { default as _91token_931dwIXU2ySUMeta } from "/vercel/path0/pages/invite/judge/[token].vue?macro=true";
import { default as _91token_93IhsOErwkvHMeta } from "/vercel/path0/pages/invite/leader/[token].vue?macro=true";
import { default as _91token_93iqURn5I1hdMeta } from "/vercel/path0/pages/invite/member/[token].vue?macro=true";
import { default as anglersqgwfQkggAhMeta } from "/vercel/path0/pages/judges/anglers.vue?macro=true";
import { default as guestsXZAHqfOXakMeta } from "/vercel/path0/pages/judges/guests.vue?macro=true";
import { default as indexWzQnkida9xMeta } from "/vercel/path0/pages/judges/index.vue?macro=true";
import { default as raffleslbCgFBcSsDMeta } from "/vercel/path0/pages/judges/raffles.vue?macro=true";
import { default as scoringhwnEwQn8VBMeta } from "/vercel/path0/pages/judges/scoring.vue?macro=true";
import { default as indexRva77tcPCRMeta } from "/vercel/path0/pages/leaderboard/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as indexGGoTHLGMaNMeta } from "/vercel/path0/pages/my-team/index.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as _91id_93k7d3qHFdrJMeta } from "/vercel/path0/pages/public/auction/[id].vue?macro=true";
import { default as _91id_93TiBOMdO4gwMeta } from "/vercel/path0/pages/public/raffle/[id].vue?macro=true";
import { default as indexVmmQZXfo8DMeta } from "/vercel/path0/pages/raffles/index.vue?macro=true";
import { default as resetjSuUNxfTnwMeta } from "/vercel/path0/pages/reset.vue?macro=true";
import { default as rootJPs62ZiDXYMeta } from "/vercel/path0/pages/root.vue?macro=true";
import { default as index5mZZHZ41OYMeta } from "/vercel/path0/pages/rules/index.vue?macro=true";
import { default as indexxaSShliqwaMeta } from "/vercel/path0/pages/schedule/index.vue?macro=true";
import { default as signCserSImh4rMeta } from "/vercel/path0/pages/sign.vue?macro=true";
import { default as signupY93ILdpGpbMeta } from "/vercel/path0/pages/signup.vue?macro=true";
import { default as termsq5qlZHSSonMeta } from "/vercel/path0/pages/terms.vue?macro=true";
import { default as indexW12fuwXRhTMeta } from "/vercel/path0/pages/thank-you/index.vue?macro=true";
import { default as verifyYegocVK4w5Meta } from "/vercel/path0/pages/verify.vue?macro=true";
import { default as backApkjVdVh6EMeta } from "/vercel/path0/pages/welcome/back.vue?macro=true";
import { default as indexRLsWSKN4GBMeta } from "/vercel/path0/pages/welcome/index.vue?macro=true";
import { default as industry_45vendorBh76nXOg4eMeta } from "/vercel/path0/pages/welcome/industry-vendor.vue?macro=true";
import { default as sponsork5USJmG05QMeta } from "/vercel/path0/pages/welcome/sponsor.vue?macro=true";
import { default as teamIwEjbUKK8yMeta } from "/vercel/path0/pages/welcome/team.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_930i5ctWJQq5Meta?.name ?? "all",
    path: _91_46_46_46all_930i5ctWJQq5Meta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_930i5ctWJQq5Meta || {},
    alias: _91_46_46_46all_930i5ctWJQq5Meta?.alias || [],
    redirect: _91_46_46_46all_930i5ctWJQq5Meta?.redirect,
    component: () => import("/vercel/path0/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: indexutcnovDtlEMeta?.name ?? "about",
    path: indexutcnovDtlEMeta?.path ?? "/about",
    meta: indexutcnovDtlEMeta || {},
    alias: indexutcnovDtlEMeta?.alias || [],
    redirect: indexutcnovDtlEMeta?.redirect,
    component: () => import("/vercel/path0/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: anglersjVbS35eJgnMeta?.name ?? "admin-anglers",
    path: anglersjVbS35eJgnMeta?.path ?? "/admin/anglers",
    meta: anglersjVbS35eJgnMeta || {},
    alias: anglersjVbS35eJgnMeta?.alias || [],
    redirect: anglersjVbS35eJgnMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/anglers.vue").then(m => m.default || m)
  },
  {
    name: announcementsLm1aQPhz1DMeta?.name ?? "admin-announcements",
    path: announcementsLm1aQPhz1DMeta?.path ?? "/admin/announcements",
    meta: announcementsLm1aQPhz1DMeta || {},
    alias: announcementsLm1aQPhz1DMeta?.alias || [],
    redirect: announcementsLm1aQPhz1DMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/announcements.vue").then(m => m.default || m)
  },
  {
    name: auction9d8jL4iduQMeta?.name ?? "admin-auction",
    path: auction9d8jL4iduQMeta?.path ?? "/admin/auction",
    meta: auction9d8jL4iduQMeta || {},
    alias: auction9d8jL4iduQMeta?.alias || [],
    redirect: auction9d8jL4iduQMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/auction.vue").then(m => m.default || m)
  },
  {
    name: dashboardYi2jyZZMHDMeta?.name ?? "admin-dashboard",
    path: dashboardYi2jyZZMHDMeta?.path ?? "/admin/dashboard",
    meta: dashboardYi2jyZZMHDMeta || {},
    alias: dashboardYi2jyZZMHDMeta?.alias || [],
    redirect: dashboardYi2jyZZMHDMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    name: djHfTXVS8oQPMeta?.name ?? "admin-dj",
    path: djHfTXVS8oQPMeta?.path ?? "/admin/dj",
    meta: djHfTXVS8oQPMeta || {},
    alias: djHfTXVS8oQPMeta?.alias || [],
    redirect: djHfTXVS8oQPMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/dj.vue").then(m => m.default || m)
  },
  {
    name: guestscBZEpVIcdFMeta?.name ?? "admin-guests",
    path: guestscBZEpVIcdFMeta?.path ?? "/admin/guests",
    meta: guestscBZEpVIcdFMeta || {},
    alias: guestscBZEpVIcdFMeta?.alias || [],
    redirect: guestscBZEpVIcdFMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/guests.vue").then(m => m.default || m)
  },
  {
    name: indexG7R2Mya2P4Meta?.name ?? "admin",
    path: indexG7R2Mya2P4Meta?.path ?? "/admin",
    meta: indexG7R2Mya2P4Meta || {},
    alias: indexG7R2Mya2P4Meta?.alias || [],
    redirect: indexG7R2Mya2P4Meta?.redirect,
    component: () => import("/vercel/path0/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: inviteswPLKclmWYXMeta?.name ?? "admin-invites",
    path: inviteswPLKclmWYXMeta?.path ?? "/admin/invites",
    meta: inviteswPLKclmWYXMeta || {},
    alias: inviteswPLKclmWYXMeta?.alias || [],
    redirect: inviteswPLKclmWYXMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/invites.vue").then(m => m.default || m)
  },
  {
    name: judgesovpAjaflPyMeta?.name ?? "admin-judges",
    path: judgesovpAjaflPyMeta?.path ?? "/admin/judges",
    meta: judgesovpAjaflPyMeta || {},
    alias: judgesovpAjaflPyMeta?.alias || [],
    redirect: judgesovpAjaflPyMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/judges.vue").then(m => m.default || m)
  },
  {
    name: leaderboardHpMdCXa3T6Meta?.name ?? "admin-leaderboard",
    path: leaderboardHpMdCXa3T6Meta?.path ?? "/admin/leaderboard",
    meta: leaderboardHpMdCXa3T6Meta || {},
    alias: leaderboardHpMdCXa3T6Meta?.alias || [],
    redirect: leaderboardHpMdCXa3T6Meta?.redirect,
    component: () => import("/vercel/path0/pages/admin/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: paymentsqWvZO2qmb3Meta?.name ?? "admin-payments",
    path: paymentsqWvZO2qmb3Meta?.path ?? "/admin/payments",
    meta: paymentsqWvZO2qmb3Meta || {},
    alias: paymentsqWvZO2qmb3Meta?.alias || [],
    redirect: paymentsqWvZO2qmb3Meta?.redirect,
    component: () => import("/vercel/path0/pages/admin/payments.vue").then(m => m.default || m)
  },
  {
    name: photosrBxQ8j16AQMeta?.name ?? "admin-photos",
    path: photosrBxQ8j16AQMeta?.path ?? "/admin/photos",
    meta: photosrBxQ8j16AQMeta || {},
    alias: photosrBxQ8j16AQMeta?.alias || [],
    redirect: photosrBxQ8j16AQMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/photos.vue").then(m => m.default || m)
  },
  {
    name: raffles_45tableaXPHSNq8tDMeta?.name ?? "admin-raffles-table",
    path: raffles_45tableaXPHSNq8tDMeta?.path ?? "/admin/raffles-table",
    meta: raffles_45tableaXPHSNq8tDMeta || {},
    alias: raffles_45tableaXPHSNq8tDMeta?.alias || [],
    redirect: raffles_45tableaXPHSNq8tDMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/raffles-table.vue").then(m => m.default || m)
  },
  {
    name: raffles0oTdJAQC0cMeta?.name ?? "admin-raffles",
    path: raffles0oTdJAQC0cMeta?.path ?? "/admin/raffles",
    meta: raffles0oTdJAQC0cMeta || {},
    alias: raffles0oTdJAQC0cMeta?.alias || [],
    redirect: raffles0oTdJAQC0cMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: random_45winners5Ce5KpxK7hMeta?.name ?? "admin-random-winners",
    path: random_45winners5Ce5KpxK7hMeta?.path ?? "/admin/random-winners",
    meta: random_45winners5Ce5KpxK7hMeta || {},
    alias: random_45winners5Ce5KpxK7hMeta?.alias || [],
    redirect: random_45winners5Ce5KpxK7hMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/random-winners.vue").then(m => m.default || m)
  },
  {
    name: scoresTR4Ls4Ws12Meta?.name ?? "admin-scores",
    path: scoresTR4Ls4Ws12Meta?.path ?? "/admin/scores",
    meta: scoresTR4Ls4Ws12Meta || {},
    alias: scoresTR4Ls4Ws12Meta?.alias || [],
    redirect: scoresTR4Ls4Ws12Meta?.redirect,
    component: () => import("/vercel/path0/pages/admin/scores.vue").then(m => m.default || m)
  },
  {
    name: settingsWTf6HiURFlMeta?.name ?? "admin-settings",
    path: settingsWTf6HiURFlMeta?.path ?? "/admin/settings",
    meta: settingsWTf6HiURFlMeta || {},
    alias: settingsWTf6HiURFlMeta?.alias || [],
    redirect: settingsWTf6HiURFlMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/settings.vue").then(m => m.default || m)
  },
  {
    name: teams97sw7hFG3eMeta?.name ?? "admin-teams",
    path: teams97sw7hFG3eMeta?.path ?? "/admin/teams",
    meta: teams97sw7hFG3eMeta || {},
    alias: teams97sw7hFG3eMeta?.alias || [],
    redirect: teams97sw7hFG3eMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/teams.vue").then(m => m.default || m)
  },
  {
    name: waitlistOZLa6IiSYOMeta?.name ?? "admin-waitlist",
    path: waitlistOZLa6IiSYOMeta?.path ?? "/admin/waitlist",
    meta: waitlistOZLa6IiSYOMeta || {},
    alias: waitlistOZLa6IiSYOMeta?.alias || [],
    redirect: waitlistOZLa6IiSYOMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/waitlist.vue").then(m => m.default || m)
  },
  {
    name: indexuGmhZybC4TMeta?.name ?? "announcements",
    path: indexuGmhZybC4TMeta?.path ?? "/announcements",
    meta: indexuGmhZybC4TMeta || {},
    alias: indexuGmhZybC4TMeta?.alias || [],
    redirect: indexuGmhZybC4TMeta?.redirect,
    component: () => import("/vercel/path0/pages/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: auctionj0CBmUpJnUMeta?.name ?? "auction",
    path: auctionj0CBmUpJnUMeta?.path ?? "/auction",
    meta: auctionj0CBmUpJnUMeta || {},
    alias: auctionj0CBmUpJnUMeta?.alias || [],
    redirect: auctionj0CBmUpJnUMeta?.redirect,
    component: () => import("/vercel/path0/pages/auction.vue").then(m => m.default || m)
  },
  {
    name: cart3AXNnOUus9Meta?.name ?? "cart",
    path: cart3AXNnOUus9Meta?.path ?? "/cart",
    meta: cart3AXNnOUus9Meta || {},
    alias: cart3AXNnOUus9Meta?.alias || [],
    redirect: cart3AXNnOUus9Meta?.redirect,
    component: () => import("/vercel/path0/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: index295g7uS6eNMeta?.name ?? "causes",
    path: index295g7uS6eNMeta?.path ?? "/causes",
    meta: index295g7uS6eNMeta || {},
    alias: index295g7uS6eNMeta?.alias || [],
    redirect: index295g7uS6eNMeta?.redirect,
    component: () => import("/vercel/path0/pages/causes/index.vue").then(m => m.default || m)
  },
  {
    name: checkout_confirmGrz9u0XPeUMeta?.name ?? "checkout_confirm",
    path: checkout_confirmGrz9u0XPeUMeta?.path ?? "/checkout_confirm",
    meta: checkout_confirmGrz9u0XPeUMeta || {},
    alias: checkout_confirmGrz9u0XPeUMeta?.alias || [],
    redirect: checkout_confirmGrz9u0XPeUMeta?.redirect,
    component: () => import("/vercel/path0/pages/checkout_confirm.vue").then(m => m.default || m)
  },
  {
    name: indexraiq4bUafSMeta?.name ?? "checkout",
    path: indexraiq4bUafSMeta?.path ?? "/checkout",
    meta: indexraiq4bUafSMeta || {},
    alias: indexraiq4bUafSMeta?.alias || [],
    redirect: indexraiq4bUafSMeta?.redirect,
    component: () => import("/vercel/path0/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: paymentuM71ATR39tMeta?.name ?? "checkout-payment",
    path: paymentuM71ATR39tMeta?.path ?? "/checkout/payment",
    meta: paymentuM71ATR39tMeta || {},
    alias: paymentuM71ATR39tMeta?.alias || [],
    redirect: paymentuM71ATR39tMeta?.redirect,
    component: () => import("/vercel/path0/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: indexRIIpWdUktDMeta?.name ?? "dashboard",
    path: indexRIIpWdUktDMeta?.path ?? "/dashboard",
    meta: indexRIIpWdUktDMeta || {},
    alias: indexRIIpWdUktDMeta?.alias || [],
    redirect: indexRIIpWdUktDMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ7PfRbLiPHMeta?.name ?? "donate",
    path: indexZ7PfRbLiPHMeta?.path ?? "/donate",
    meta: indexZ7PfRbLiPHMeta || {},
    alias: indexZ7PfRbLiPHMeta?.alias || [],
    redirect: indexZ7PfRbLiPHMeta?.redirect,
    component: () => import("/vercel/path0/pages/donate/index.vue").then(m => m.default || m)
  },
  {
    name: embedBgEWVpGAHyMeta?.name ?? "embed",
    path: embedBgEWVpGAHyMeta?.path ?? "/embed",
    meta: embedBgEWVpGAHyMeta || {},
    alias: embedBgEWVpGAHyMeta?.alias || [],
    redirect: embedBgEWVpGAHyMeta?.redirect,
    component: () => import("/vercel/path0/pages/embed.vue").then(m => m.default || m)
  },
  {
    name: indexBDH30Z85iOMeta?.name ?? "forgot",
    path: indexBDH30Z85iOMeta?.path ?? "/forgot",
    meta: indexBDH30Z85iOMeta || {},
    alias: indexBDH30Z85iOMeta?.alias || [],
    redirect: indexBDH30Z85iOMeta?.redirect,
    component: () => import("/vercel/path0/pages/forgot/index.vue").then(m => m.default || m)
  },
  {
    name: sentcZrERVUUBtMeta?.name ?? "forgot-sent",
    path: sentcZrERVUUBtMeta?.path ?? "/forgot/sent",
    meta: sentcZrERVUUBtMeta || {},
    alias: sentcZrERVUUBtMeta?.alias || [],
    redirect: sentcZrERVUUBtMeta?.redirect,
    component: () => import("/vercel/path0/pages/forgot/sent.vue").then(m => m.default || m)
  },
  {
    name: indexoqVizrzsXuMeta?.name ?? "honoree",
    path: indexoqVizrzsXuMeta?.path ?? "/honoree",
    meta: indexoqVizrzsXuMeta || {},
    alias: indexoqVizrzsXuMeta?.alias || [],
    redirect: indexoqVizrzsXuMeta?.redirect,
    component: () => import("/vercel/path0/pages/honoree/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93VwOOSYwjSdMeta?.name ?? "invite-token",
    path: _91token_93VwOOSYwjSdMeta?.path ?? "/invite/:token()",
    meta: _91token_93VwOOSYwjSdMeta || {},
    alias: _91token_93VwOOSYwjSdMeta?.alias || [],
    redirect: _91token_93VwOOSYwjSdMeta?.redirect,
    component: () => import("/vercel/path0/pages/invite/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_931dwIXU2ySUMeta?.name ?? "invite-judge-token",
    path: _91token_931dwIXU2ySUMeta?.path ?? "/invite/judge/:token()",
    meta: _91token_931dwIXU2ySUMeta || {},
    alias: _91token_931dwIXU2ySUMeta?.alias || [],
    redirect: _91token_931dwIXU2ySUMeta?.redirect,
    component: () => import("/vercel/path0/pages/invite/judge/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93IhsOErwkvHMeta?.name ?? "invite-leader-token",
    path: _91token_93IhsOErwkvHMeta?.path ?? "/invite/leader/:token()",
    meta: _91token_93IhsOErwkvHMeta || {},
    alias: _91token_93IhsOErwkvHMeta?.alias || [],
    redirect: _91token_93IhsOErwkvHMeta?.redirect,
    component: () => import("/vercel/path0/pages/invite/leader/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93iqURn5I1hdMeta?.name ?? "invite-member-token",
    path: _91token_93iqURn5I1hdMeta?.path ?? "/invite/member/:token()",
    meta: _91token_93iqURn5I1hdMeta || {},
    alias: _91token_93iqURn5I1hdMeta?.alias || [],
    redirect: _91token_93iqURn5I1hdMeta?.redirect,
    component: () => import("/vercel/path0/pages/invite/member/[token].vue").then(m => m.default || m)
  },
  {
    name: anglersqgwfQkggAhMeta?.name ?? "judges-anglers",
    path: anglersqgwfQkggAhMeta?.path ?? "/judges/anglers",
    meta: anglersqgwfQkggAhMeta || {},
    alias: anglersqgwfQkggAhMeta?.alias || [],
    redirect: anglersqgwfQkggAhMeta?.redirect,
    component: () => import("/vercel/path0/pages/judges/anglers.vue").then(m => m.default || m)
  },
  {
    name: guestsXZAHqfOXakMeta?.name ?? "judges-guests",
    path: guestsXZAHqfOXakMeta?.path ?? "/judges/guests",
    meta: guestsXZAHqfOXakMeta || {},
    alias: guestsXZAHqfOXakMeta?.alias || [],
    redirect: guestsXZAHqfOXakMeta?.redirect,
    component: () => import("/vercel/path0/pages/judges/guests.vue").then(m => m.default || m)
  },
  {
    name: indexWzQnkida9xMeta?.name ?? "judges",
    path: indexWzQnkida9xMeta?.path ?? "/judges",
    meta: indexWzQnkida9xMeta || {},
    alias: indexWzQnkida9xMeta?.alias || [],
    redirect: indexWzQnkida9xMeta?.redirect,
    component: () => import("/vercel/path0/pages/judges/index.vue").then(m => m.default || m)
  },
  {
    name: raffleslbCgFBcSsDMeta?.name ?? "judges-raffles",
    path: raffleslbCgFBcSsDMeta?.path ?? "/judges/raffles",
    meta: raffleslbCgFBcSsDMeta || {},
    alias: raffleslbCgFBcSsDMeta?.alias || [],
    redirect: raffleslbCgFBcSsDMeta?.redirect,
    component: () => import("/vercel/path0/pages/judges/raffles.vue").then(m => m.default || m)
  },
  {
    name: scoringhwnEwQn8VBMeta?.name ?? "judges-scoring",
    path: scoringhwnEwQn8VBMeta?.path ?? "/judges/scoring",
    meta: scoringhwnEwQn8VBMeta || {},
    alias: scoringhwnEwQn8VBMeta?.alias || [],
    redirect: scoringhwnEwQn8VBMeta?.redirect,
    component: () => import("/vercel/path0/pages/judges/scoring.vue").then(m => m.default || m)
  },
  {
    name: indexRva77tcPCRMeta?.name ?? "leaderboard",
    path: indexRva77tcPCRMeta?.path ?? "/leaderboard",
    meta: indexRva77tcPCRMeta || {},
    alias: indexRva77tcPCRMeta?.alias || [],
    redirect: indexRva77tcPCRMeta?.redirect,
    component: () => import("/vercel/path0/pages/leaderboard/index.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexGGoTHLGMaNMeta?.name ?? "my-team",
    path: indexGGoTHLGMaNMeta?.path ?? "/my-team",
    meta: indexGGoTHLGMaNMeta || {},
    alias: indexGGoTHLGMaNMeta?.alias || [],
    redirect: indexGGoTHLGMaNMeta?.redirect,
    component: () => import("/vercel/path0/pages/my-team/index.vue").then(m => m.default || m)
  },
  {
    name: privacykJ3SQnmrN4Meta?.name ?? "privacy",
    path: privacykJ3SQnmrN4Meta?.path ?? "/privacy",
    meta: privacykJ3SQnmrN4Meta || {},
    alias: privacykJ3SQnmrN4Meta?.alias || [],
    redirect: privacykJ3SQnmrN4Meta?.redirect,
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: _91id_93k7d3qHFdrJMeta?.name ?? "public-auction-id",
    path: _91id_93k7d3qHFdrJMeta?.path ?? "/public/auction/:id()",
    meta: _91id_93k7d3qHFdrJMeta || {},
    alias: _91id_93k7d3qHFdrJMeta?.alias || [],
    redirect: _91id_93k7d3qHFdrJMeta?.redirect,
    component: () => import("/vercel/path0/pages/public/auction/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93TiBOMdO4gwMeta?.name ?? "public-raffle-id",
    path: _91id_93TiBOMdO4gwMeta?.path ?? "/public/raffle/:id()",
    meta: _91id_93TiBOMdO4gwMeta || {},
    alias: _91id_93TiBOMdO4gwMeta?.alias || [],
    redirect: _91id_93TiBOMdO4gwMeta?.redirect,
    component: () => import("/vercel/path0/pages/public/raffle/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVmmQZXfo8DMeta?.name ?? "raffles",
    path: indexVmmQZXfo8DMeta?.path ?? "/raffles",
    meta: indexVmmQZXfo8DMeta || {},
    alias: indexVmmQZXfo8DMeta?.alias || [],
    redirect: indexVmmQZXfo8DMeta?.redirect,
    component: () => import("/vercel/path0/pages/raffles/index.vue").then(m => m.default || m)
  },
  {
    name: resetjSuUNxfTnwMeta?.name ?? "reset",
    path: resetjSuUNxfTnwMeta?.path ?? "/reset",
    meta: resetjSuUNxfTnwMeta || {},
    alias: resetjSuUNxfTnwMeta?.alias || [],
    redirect: resetjSuUNxfTnwMeta?.redirect,
    component: () => import("/vercel/path0/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: rootJPs62ZiDXYMeta?.name ?? "root",
    path: rootJPs62ZiDXYMeta?.path ?? "/root",
    meta: rootJPs62ZiDXYMeta || {},
    alias: rootJPs62ZiDXYMeta?.alias || [],
    redirect: rootJPs62ZiDXYMeta?.redirect,
    component: () => import("/vercel/path0/pages/root.vue").then(m => m.default || m)
  },
  {
    name: index5mZZHZ41OYMeta?.name ?? "rules",
    path: index5mZZHZ41OYMeta?.path ?? "/rules",
    meta: index5mZZHZ41OYMeta || {},
    alias: index5mZZHZ41OYMeta?.alias || [],
    redirect: index5mZZHZ41OYMeta?.redirect,
    component: () => import("/vercel/path0/pages/rules/index.vue").then(m => m.default || m)
  },
  {
    name: indexxaSShliqwaMeta?.name ?? "schedule",
    path: indexxaSShliqwaMeta?.path ?? "/schedule",
    meta: indexxaSShliqwaMeta || {},
    alias: indexxaSShliqwaMeta?.alias || [],
    redirect: indexxaSShliqwaMeta?.redirect,
    component: () => import("/vercel/path0/pages/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: signCserSImh4rMeta?.name ?? "sign",
    path: signCserSImh4rMeta?.path ?? "/sign",
    meta: signCserSImh4rMeta || {},
    alias: signCserSImh4rMeta?.alias || [],
    redirect: signCserSImh4rMeta?.redirect,
    component: () => import("/vercel/path0/pages/sign.vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup",
    path: signupY93ILdpGpbMeta?.path ?? "/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: termsq5qlZHSSonMeta?.name ?? "terms",
    path: termsq5qlZHSSonMeta?.path ?? "/terms",
    meta: termsq5qlZHSSonMeta || {},
    alias: termsq5qlZHSSonMeta?.alias || [],
    redirect: termsq5qlZHSSonMeta?.redirect,
    component: () => import("/vercel/path0/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: indexW12fuwXRhTMeta?.name ?? "thank-you",
    path: indexW12fuwXRhTMeta?.path ?? "/thank-you",
    meta: indexW12fuwXRhTMeta || {},
    alias: indexW12fuwXRhTMeta?.alias || [],
    redirect: indexW12fuwXRhTMeta?.redirect,
    component: () => import("/vercel/path0/pages/thank-you/index.vue").then(m => m.default || m)
  },
  {
    name: verifyYegocVK4w5Meta?.name ?? "verify",
    path: verifyYegocVK4w5Meta?.path ?? "/verify",
    meta: verifyYegocVK4w5Meta || {},
    alias: verifyYegocVK4w5Meta?.alias || [],
    redirect: verifyYegocVK4w5Meta?.redirect,
    component: () => import("/vercel/path0/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: backApkjVdVh6EMeta?.name ?? "welcome-back",
    path: backApkjVdVh6EMeta?.path ?? "/welcome/back",
    meta: backApkjVdVh6EMeta || {},
    alias: backApkjVdVh6EMeta?.alias || [],
    redirect: backApkjVdVh6EMeta?.redirect,
    component: () => import("/vercel/path0/pages/welcome/back.vue").then(m => m.default || m)
  },
  {
    name: indexRLsWSKN4GBMeta?.name ?? "welcome",
    path: indexRLsWSKN4GBMeta?.path ?? "/welcome",
    meta: indexRLsWSKN4GBMeta || {},
    alias: indexRLsWSKN4GBMeta?.alias || [],
    redirect: indexRLsWSKN4GBMeta?.redirect,
    component: () => import("/vercel/path0/pages/welcome/index.vue").then(m => m.default || m)
  },
  {
    name: industry_45vendorBh76nXOg4eMeta?.name ?? "welcome-industry-vendor",
    path: industry_45vendorBh76nXOg4eMeta?.path ?? "/welcome/industry-vendor",
    meta: industry_45vendorBh76nXOg4eMeta || {},
    alias: industry_45vendorBh76nXOg4eMeta?.alias || [],
    redirect: industry_45vendorBh76nXOg4eMeta?.redirect,
    component: () => import("/vercel/path0/pages/welcome/industry-vendor.vue").then(m => m.default || m)
  },
  {
    name: sponsork5USJmG05QMeta?.name ?? "welcome-sponsor",
    path: sponsork5USJmG05QMeta?.path ?? "/welcome/sponsor",
    meta: sponsork5USJmG05QMeta || {},
    alias: sponsork5USJmG05QMeta?.alias || [],
    redirect: sponsork5USJmG05QMeta?.redirect,
    component: () => import("/vercel/path0/pages/welcome/sponsor.vue").then(m => m.default || m)
  },
  {
    name: teamIwEjbUKK8yMeta?.name ?? "welcome-team",
    path: teamIwEjbUKK8yMeta?.path ?? "/welcome/team",
    meta: teamIwEjbUKK8yMeta || {},
    alias: teamIwEjbUKK8yMeta?.alias || [],
    redirect: teamIwEjbUKK8yMeta?.redirect,
    component: () => import("/vercel/path0/pages/welcome/team.vue").then(m => m.default || m)
  }
]