import * as Sentry from '@sentry/vue'
import type { User } from '~/types/prisma'

export const useSentry = () => {
  const captureException = (error: any) => {
    Sentry.captureException(error)
  }

  const captureMessage = (message: string) => {
    Sentry.captureMessage(message)
  }

  const identifyUser = (user: User | null) => {
    Sentry.setUser(user
      ? {
          id: user.id,
          email: user.email,
          username: `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim(),
          segment: user.role,
        }
      : null)
  }

  return {
    captureException,
    captureMessage,
    identifyUser,
  }
}
