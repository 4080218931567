export const appName = 'Borracho Pescador'
export const appDescription = 'Fishing for the future'

export const fractionOptions = [
  {
    label: '0',
    value: 0.0,
  },
  {
    label: '1/8',
    value: 0.125,
  },
  {
    label: '1/4',
    value: 0.125 * 2,
  },
  {
    label: '3/8',
    value: 0.125 * 3,
  },
  {
    label: '1/2',
    value: 0.125 * 4,
  },
  {
    label: '5/8',
    value: 0.125 * 5,
  },
  {
    label: '3/4',
    value: 0.125 * 6,
  },
  {
    label: '7/8',
    value: 0.125 * 7,
  },
]

export const inchOptions = [
  { label: '20″', value: 20 },
  { label: '21″', value: 21 },
  { label: '22″', value: 22 },
  { label: '23″', value: 23 },
  { label: '24″', value: 24 },
  { label: '25″', value: 25 },
  { label: '26″', value: 26 },
  { label: '27″', value: 27 },
  { label: '28″', value: 28 },
]
