import revive_payload_client_fNK15zveXZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.62.3_async-validator@4._4y5z6g24ffhybde6lqg5cfvq5i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jbIXeSC5Yj from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.62.3_async-validator@4._4y5z6g24ffhybde6lqg5cfvq5i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_hjCM7j3s4A from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.62.3_async-validator@4._4y5z6g24ffhybde6lqg5cfvq5i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import check_outdated_build_client_lwhqJuDuD2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.62.3_async-validator@4._4y5z6g24ffhybde6lqg5cfvq5i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_71kTK2ROL9 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.16.4_typescript@4.9.5_vue@3.4.25_typescript@4.9.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_oFgXxHveeF from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.62.3_async-validator@4._4y5z6g24ffhybde6lqg5cfvq5i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_rghuME3c5h from "/vercel/path0/node_modules/.pnpm/dayjs-nuxt@1.2.7_rollup@4.16.4/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import composition_QEz5ARFO2S from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_rollup@4.16.4_vue-router@4.3.2_vue@3.4.25_typescript@4.9.5___vue@3.4.25_typescript@4.9.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_H5m13AyOo9 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_rollup@4.16.4_vue-router@4.3.2_vue@3.4.25_typescript@4.9.5___vue@3.4.25_typescript@4.9.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/.nuxt/formkitPlugin.mjs";
import templates_pwa_client_579b072e_Ra3LPvrs4K from "/vercel/path0/.nuxt/templates.pwa.client.579b072e.ts";
import plugin_client_QjTif4AG12 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.0_rollup@4.16.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_hPX7KBlj48 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.62.3_async-validator@4._4y5z6g24ffhybde6lqg5cfvq5i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_Yf8XFpcWiv from "/vercel/path0/node_modules/.pnpm/@vueuse+nuxt@9.13.0_nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.62._bvvfj25vss7kz7zzulmu3akd4u/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import plugin_sKofI2MxUk from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.4.11_rollup@4.16.4_typescript@4.9.5_vue_sa6fbjcxkqkyz77kmtbf5fu3du/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import sentry_3AyO8nEfhE from "/vercel/path0/plugins/sentry.ts";
import naive_ui_cjnU5TlW5h from "/vercel/path0/plugins/naive-ui.ts";
import vue_query_wrmMkNpEpe from "/vercel/path0/plugins/vue-query.ts";
export default [
  revive_payload_client_fNK15zveXZ,
  unhead_jbIXeSC5Yj,
  router_hjCM7j3s4A,
  check_outdated_build_client_lwhqJuDuD2,
  plugin_vue3_71kTK2ROL9,
  components_plugin_KR1HBZs4kY,
  prefetch_client_oFgXxHveeF,
  plugin_rghuME3c5h,
  composition_QEz5ARFO2S,
  i18n_H5m13AyOo9,
  formkitPlugin_pZqjah0RUG,
  templates_pwa_client_579b072e_Ra3LPvrs4K,
  plugin_client_QjTif4AG12,
  chunk_reload_client_hPX7KBlj48,
  ssr_plugin_Yf8XFpcWiv,
  plugin_sKofI2MxUk,
  sentry_3AyO8nEfhE,
  naive_ui_cjnU5TlW5h,
  vue_query_wrmMkNpEpe
]