<script lang="ts" setup>
import { useAlertStore } from '~/stores/alert'

const alertStore = useAlertStore()

const alerts = computed(() => alertStore.alerts)

const themeClassByState = {
  success: 'theme-green',
  error: 'theme-error',
  warning: 'theme-warning',
  info: 'theme-neutral-light',
}

const iconNameByState = {
  success: 'ic:round-check-circle-outline',
  error: 'ic:round-report-gmailerrorred',
  warning: 'ic:round-warning-amber',
  info: 'ic:outline-info',
}
</script>

<template>
  <CommonContainer class="fixed left-0 top-5 lg:top-10 right-0 mx-auto pointer-events-none z-[1000]">
    <div class="relative">
      <TransitionGroup name="list">
        <div v-for="alert in alerts" :key="alert.id" class="w-full pb-4">
          <div class="flex items-center shadow-lg bg-t-bg rounded-2xl shadow-t-bg/25 py-2.5 lg:py-4 px-3 lg:px-5 text-t-bg text-t-text-light gap-2.5 lg:gap-3 pointer-events-auto" :class="themeClassByState[alert.type]">
            <Icon :name="iconNameByState[alert.type]" class="w-5 h-5 lg:w-6 lg:h-6" />
            <span class="text-t-text text-sm flex-1">{{ alert.message }}</span>
            <button
              type="button"
              class="border rounded-lg border-t-border text-t-text w-6 h-6 lg:w-7 lg:h-7 flex items-center justify-center shrink-0 transition-colors hover:border-t-text"
              @click="alertStore.removeAlert(alert.id)"
            >
              <Icon name="ri:close-line" class="w-4 h-4" />
            </button>
          </div>
        </div>
      </TransitionGroup>
    </div>
  </CommonContainer>
</template>
