<template>
  <DocusealForm
    :src="'https://docuseal.co/d/LEVGR9rhZYf86M'"
    :email="'signer@example.com'"
    :send-copy-email="true"
    @complete="onFormComplete"
  />
</template>

<script>
import { DocusealForm } from '@docuseal/vue'

export default {
  name: 'App',
  components: {
    DocusealForm
  },
  methods: {
    onFormComplete (data) {
      console.log(data)
    }
  }
}
</script>