import * as Sentry from '@sentry/vue'

// @ts-expect-error No type provided by Nuxt
export default ({ vueApp }, inject) => {
  const config = useRuntimeConfig()

  Sentry.init({
    Vue: vueApp,
    dsn: config.public.sentry.dsn,
    environment: config.public.environment,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^https:\/\/borrachopescador\.org\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(vueApp.$nuxt.$router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
